/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "When you set out for Ithaca", React.createElement(_components.br), "\n", "I hope your road will be long,", React.createElement(_components.br), "\n", "Full of adventure, full of discovery."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Always keep Ithaca in your mind.", React.createElement(_components.br), "\n", "To get there is your destiny.", React.createElement(_components.br), "\n", "But don't rush the journey.", React.createElement(_components.br), "\n", "It's better if it lasts for years,", React.createElement(_components.br), "\n", "so that you'll be old when you reach the island,", React.createElement(_components.br), "\n", "Wealthy with all you've gained along the way,", React.createElement(_components.br), "\n", "Not expecting Ithaca to make you rich."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Ithaca gave thee the wonderful voyage.", React.createElement(_components.br), "\n", "Through her alone you went out", React.createElement(_components.br), "\n", "Now she has nothing more to give you."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "If you find her poor, Ithaca will not have deceived you.", React.createElement(_components.br), "\n", "So wise you will have become, so full of experience,", React.createElement(_components.br), "\n", "You will have understood by then what these Ithacas mean."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
